<template>
    <div class="sceneryCateList">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>推广海报列表</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="search">
        <span>海报名称</span>
        <el-input v-model="list.title" placeholder="请输入海报名称"></el-input>
        <!-- <span>海报类型</span>
        <el-select
          clearable
          v-model="list.type"
          placeholder="请选择"
          style="margin:0 20px"
        >
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select> -->
        <span>海报状态</span>
        <el-select
          clearable
          v-model="list.status"
          placeholder="请选择"
          style="margin:0 20px"
        >
          <el-option
            v-for="item in statusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button type="primary" @click="getPosterList()">查找</el-button>
      </div>
      <el-table :data="tableData" border>
        <el-table-column align="center" prop="dept" label="排序">
          <template slot-scope="{ row }">
            <el-input
              class="input-new-tag"
              v-if="row.inputVisible"
              v-model="row.dept"
              ref="saveTagInput"
              size="small"
              @input="onInput"
              @keyup.enter.native="handleInputConfirm(row)"
              @blur="handleInputConfirm(row)"
            >
            </el-input>
            <el-button
              v-else
              class="button-new-tag"
              size="small"
              @click="onChangeSort(row)"
              >{{ row.dept }}</el-button
            >
          </template>
        </el-table-column>
        <el-table-column align="center" prop="tjId" label="ID">
        </el-table-column>
        <el-table-column align="center" prop="title" label="名称">
        </el-table-column>
        <!-- <el-table-column align="center" prop="type" label="类型">
        </el-table-column> -->
        <!-- <el-table-column align="center" prop="showImg" label="展示图">
          <template slot-scope="{ row }">
            <el-image
              class="showimg"
              :preview-src-list="[row.showImg]"
              :src="row.showImg"
            ></el-image>
          </template>
        </el-table-column> -->
        <el-table-column align="center" prop="bgImg" label="背景图">
          <template slot-scope="{ row }">
            <el-image
              class="showimg"
              :preview-src-list="[row.bgImg]"
              :src="row.bgImg"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="headCoordinate" label="头像坐标">
        </el-table-column>
        <el-table-column align="center" prop="headSize" label="头像大小">
        </el-table-column>
        <el-table-column align="center" prop="qrcodeCoordinate" label="二维码坐标">
        </el-table-column>
        <el-table-column align="center" prop="qrcodeSize" label="二维码大小">
        </el-table-column>
        <el-table-column align="center" prop="strStatus" label="状态">
        </el-table-column>
        <el-table-column align="center" prop="" label="操作" width="300">
          <template slot-scope="{ row }">
            <el-button
              size="mini"
              v-if="row.status === 1"
              type="primary"
              @click="changeStatus(row.tjId, 2)"
              >禁用</el-button
            >
            <el-button
              size="mini"
              v-if="row.status === 2"
              type="primary"
              @click="changeStatus(row.tjId, 1)"
              >启用</el-button
            >
            <el-button
              size="mini"
              type="primary"
              @click="onEditCalsssA(row)"
            
              >修改</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="list.currentPage"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="list.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.total"
      >
      </el-pagination>
      <el-dialog
        title="编辑分类"
        :visible.sync="editClassADialogVisible"
        width="30%"
        class="editDialog"
      >
        <el-form ref="form" label-width="100px">
          <el-form-item label="海报名称">
            <el-input
              v-model="editClassAForm.title"
              placeholder="请输入海报名称"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="海报展示图">
            <send-image
              v-if="editClassADialogVisible"
              :images="editClassAForm.showImg"
              type="one"
              @addImageStr="(e) => addImageStr(e, 1)"
            ></send-image>
          </el-form-item> -->
          <el-form-item label="海报背景图">
            <send-image
              v-if="editClassADialogVisible"
              :images="editClassAForm.bgImg"
              type="one"
              @addImageStr="(e) => addImageStr(e, 2)"
            ></send-image>
          </el-form-item>
          <el-form-item
            ><span>建议尺寸：535*951px</span></el-form-item
          >
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editClassADialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="onEditCateClassAOk">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </template>
  <script>
  import {
    getPosterList,
    updatePosterStatus,
    modifyPosterDept,
    modifyPoster
  } from "../../api/vip";
  import SendImage from "../../components/sendImage.vue";
  export default {
    name: "sceneryCateList",
    components: {
      SendImage,
    },
    data() {
      return {
        addClassADialogVisible: false,
        editClassADialogVisible: false,
        options: [],
        addClassAForm: {
          cateName: "",
          fatherId: null,
          cateId: 0,
          cateImg: "",
        },
        editClassAForm: {},
        list: {
          currentPage: 1,
          pageSize: 10,
          title: '',
          type: 0,
          status:0
        },
        tableData: [],
        pagination: {},
        typeList: [
          {
            value: 0,
            label: "全部",
          },
          {
            value: 1,
            label: "店铺码",
          },
          {
            value: 2,
            label: "分销码",
          }
        ],
        statusList: [
          {
            value: 0,
            label: "全部",
          },
          {
            value: 1,
            label: "启用",
          },
          {
            value: 2,
            label: "禁用",
          }
        ],
      };
    },
    created() {
      this.getPosterList();
    },
    methods: {
        async getPosterList() {
        const { data } = await getPosterList(this.list);
        this.pagination = data.pagination;
        let arr = [
          {headCoordinate:'（428，17）',headSize:'67*67px',qrcodeCoordinate:'（334，752）',qrcodeSize:'156*156px'},
          {headCoordinate:'（428，17）',headSize:'67*67px',qrcodeCoordinate:'（334，752）',qrcodeSize:'156*156px'},
          {headCoordinate:'（428，17）',headSize:'67*67px',qrcodeCoordinate:'（334，752）',qrcodeSize:'156*156px'},
          {headCoordinate:'（428，17）',headSize:'67*67px',qrcodeCoordinate:'（334，752）',qrcodeSize:'156*156px'},
          {headCoordinate:'（428，17）',headSize:'67*67px',qrcodeCoordinate:'（334，752）',qrcodeSize:'156*156px'},
          {headCoordinate:'（428，17）',headSize:'67*67px',qrcodeCoordinate:'（334，752）',qrcodeSize:'156*156px'},
          {headCoordinate:'（428，17）',headSize:'67*67px',qrcodeCoordinate:'（334，752）',qrcodeSize:'156*156px'},
          {headCoordinate:'（428，17）',headSize:'67*67px',qrcodeCoordinate:'（334，752）',qrcodeSize:'156*156px'},
          {headCoordinate:'（428，17）',headSize:'67*67px',qrcodeCoordinate:'（334，752）',qrcodeSize:'156*156px'},
          {headCoordinate:'（428，17）',headSize:'67*67px',qrcodeCoordinate:'（334，752）',qrcodeSize:'156*156px'},
        ]
        data.list.map((item,index) => {
          item.inputVisible = false;
          data.list[index] = {...item,...arr[index]}
        });
        this.tableData = data.list;
       console.log(this.tableData)
      },
      changeStatus(tjId, status) {
        this.$confirm("是否操作这条数据?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            const { data } = await updatePosterStatus({
              tjId,
              status
            });
            if (data.code == 0) {
              this.$message({
                type: "success",
                message: data.msg,
              });
              this.getPosterList();
            } else {
              this.$message({
                type: "error",
                message: data.msg,
              });
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      },
  
      onInput(value) {
        var reg = /^[0-9]*[1-9][0-9]*$/;
        var reg2 = /^-[0-9]*[1-9][0-9]*$/;
        if (!reg.test(value) && !reg2.test(value)) {
          return this.$message({
            type: "warning",
            message: "请输入整数!",
          });
        }
      },
      async onEditCateClassAOk() {
        if (
          this.editClassAForm.title === "" ||
          this.editClassAForm.showImg === "" ||
          this.editClassAForm.bgImg === ""
        ) {
          return this.$message.warning("请把数据填写完整!");
        }
        const { data } = await modifyPoster(this.editClassAForm);
        console.log(data);
        if (data.code === 0) {
          this.$message({
            type: "success",
            message: data.msg,
          });
          this.getPosterList();
        } else {
        }
        this.editClassADialogVisible = false;
      },
      onEditCalsssA(row) {
        console.log(row);
        this.editClassADialogVisible = true;
        this.editClassAForm = {...row}
      },
      onChangeSort(row) {
        row.inputVisible = true;
      },
      async handleInputConfirm(row) {
        const { data } = await modifyPosterDept({
            tjId: row.tjId,
          dept: Number(row.dept),
        });
        row.inputVisible = false;
        this.getPosterList();
      },
      
      //上传图片
      addImageStr(image, type) {
        if (type === 1) {
          this.editClassAForm.showImg = image;
        } else if (type === 2) {
          this.editClassAForm.bgImg = image;
        }
      },
      handleSizeChange(num) {
        this.list.pageSize = num;
        this.getPosterList();
      },
      handleCurrentChange(num) {
        this.list.currentPage = num;
        this.getPosterList();
      },
    },
  };
  </script>
  <style lang="less" scoped>
  .sceneryCateList {
    .el-breadcrumb {
      height: 35px;
      border-bottom: 1px solid rgb(192, 191, 191);
    }
    .search {
      margin-top: 50px;
      .el-input {
        width: 200px;
        margin: 0 20px;
      }
    }
    .el-table {
      margin-top: 50px;
      .el-table__body-wrapper {
        .el-table__body {
          tbody {
            td {
              .showimg {
                width: 80px;
                height: 80px;
              }
            }
          }
        }
      }
    }
    /deep/.addDialog,
    /deep/ .editDialog {
      .el-dialog__body {
        .el-form {
          .el-form-item__content {
            display: flex;
            span {
              color: red;
            }
            .img {
              position: relative;
              height: 100px;
              width: 100px;
              margin: 0 20px;
              img {
                height: 100px;
                width: 100px;
                border: 1px solid #c0ccda;
              }
              div {
                position: absolute;
                top: 0;
                right: 5px;
                height: 10px;
                width: 10px;
                z-index: 100;
              }
            }
            .addImg {
              display: inline-block;
              width: 100px;
              height: 100px;
              border: 1px dashed #c0ccda;
              text-align: center;
              line-height: 100px;
              cursor: pointer;
              i {
                width: 50px;
                height: 50px;
                font-size: 20px;
                color: #c0ccda;
              }
            }
          }
        }
      }
    }
    .el-pagination {
      margin-top: 50px;
      float: right;
    }
  }
  </style>